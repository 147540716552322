<template>
  <div class="px-main --dark py-4">
    <v-row>
      <v-col :cols="6">
        <h1 class="page-title-main">
          その他予約 新規予約作成
        </h1>
      </v-col>
      <v-col :cols="6" class="d-flex justify-end">
        <v-btn
          color="var(--bt__red)"
          class="mr-3 d-flex align-center btn_status white--text"
          href="javascript:window.open('','_self').close();"
        >
          <v-icon class="white--text"> mdi-close </v-icon>
          {{ $t("common.close") }}
        </v-btn>
        <v-btn
          class="white--text"
          color="var(--bt__blue)"
          @click="createBookingNoCustomerId()"
          :disabled="!checkPerUser"
        >
          {{ $t("modalReservation.title") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-card class="mt-5">
        <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <!-- Start Left Input -->
              <v-col :cols="6" class="mt-5">
                    <v-card
                        class="ml-8 mx-4 px-10 py-5"
                        color="var(--bg__secondary)"
                        elevation="5"
                    >
                        <v-row>
                            <v-btn
                                class="white--text mt-3"
                                color="var(--bt__blue)"
                                to="/reservation/calendar-block?type=reservation-without-customer-ID"
                            >
                                {{ $t("contractDetail.addFromCalendar") }}
                            </v-btn>
                            <v-checkbox
                                v-model="finishData.priorityBooking"
                                label="優先予約"
                                class="ml-6 checkbox-priority-booking"
                            ></v-checkbox>
                        </v-row>
                        <v-row class="mt-5">
                            <v-col :cols="6">
                                <div>
                                <span class="label-title">{{
                                    $t("contractDetail.selectFacility")
                                }}</span>
                                <br />
                                <p class="mt-3 text-field">{{ facility }}</p>
                                </div>
                            </v-col>

                            <v-col :cols="3">
                                <div>
                                <span class="label-title">{{
                                    $t("common.roomType")
                                }}</span>
                                <br />
                                <p class="mt-3 text-field">{{ roomTypeName }}</p>
                                </div>
                            </v-col>
                        </v-row>

                        <!-- date time -->
                        <div class="mt-3 d-flex ml-4">
                        <v-col :cols="3">
                            <div>
                            <span class="label-title">{{ $t("common.checkIn") }}</span>
                            </div>
                            <div>
                            <v-icon class="ml-n8 mr-2" color="#333333">mdi-calendar-month</v-icon>

                            <span class="date-string">
                                {{ formatDate(finishData.checkInDate) }}
                            </span>
                            </div>
                        </v-col>
                        <v-col :cols="1" class="d-flex align-end mb-1" style="color: #000000"> ~ </v-col>
                        <v-col :cols="3">
                            <div>
                            <span class="label-title">{{ $t("common.checkOut") }}</span>
                            </div>
                            <div>
                            <v-icon class="ml-n8 mr-2" color="#333333">mdi-calendar-month</v-icon>

                            <span class="date-string">
                                {{ formatDate(checkOutDate) }}
                            </span>
                            </div>
                        </v-col>
                        <v-col :cols="3" class="d-flex align-end pt-0">
                            <span class="text-size-normal night-day">
                            {{ $route.query.night }}{{ $t("contractDetail.night") }}</span
                            >
                        </v-col>
                        </div>
                        <!-- select -->
                        <div class="mt-4 d-flex">
                            <v-row>
                                <v-col :cols="4">
                                <span class="label-title">{{
                                    $t("common.adult")
                                }}</span>
                                <v-combobox class="combo-box" :items="numberOfAdultItems" v-model="finishData.numberOfAdults" :rules="rules.numberOfAdults"></v-combobox>
                                </v-col>
                                <v-col :cols="4">
                                <span class="label-title">{{
                                    $t("contractDetail.nonSleepChild")
                                }}</span>
                                <v-combobox class="combo-box" :items="numberOfChildItems" v-model="finishData.numberOfChildren" :rules="rules.numberOfChildren"></v-combobox>
                                </v-col>
                                <v-col :cols="4">
                                <span class="label-title">{{
                                    $t("contractDetail.sleepChild")
                                }}</span>
                                <v-combobox class="combo-box" :items="numberOfBedshareItems" v-model="finishData.numberOfChildrenWithBedShare" :rules="rules.numberOfChildrenWithBedShare"></v-combobox>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card>
            </v-col>
            <!-- End Left Input -->
                    <!-- Start Right Input -->
                    <v-col :cols="6" class="px-10 py-7">
                    <div class="mx-4 pa-0">
                        <v-col :cols="5" class="pa-0">
                        <div>
                            <span class="label-title">{{$t('contractDetail.remarkNote')}}</span>
                            <v-text-field
                            v-model="finishData.bookingBarNote"
                            class="text-field pt-0"
                            :rules="[$rules.checkLenghInput(255)]"
                            >
                            </v-text-field>
                        </div>
                        </v-col>
                        <div>
                            <span class="label-title">宿泊代表者</span>
                        <v-text-field
                            v-model="finishData.representativeName"
                            class="text-field pt-0"
                            :rules="[$rules.checkLenghInput(255)]"
                        ></v-text-field>
                        </div>
                        <div>
                            <span class="label-title">宿泊代表者ヨミガナ</span>
                        <v-text-field
                            v-model="finishData.representativeKana"
                            class="text-field pt-0"
                            :rules="[$rules.checkKatakana, $rules.checkLenghInput(255)]"
                        >
                        </v-text-field>
                        </div>
                        <div>
                             <span class="label-title">電話番号</span>
                        <!-- <v-text-field
                            v-model="finishData.representativeTel"
                            class="text-field pt-0"
                            :rules="[$rules.checkNumber, $rules.checkLenghInput(15)]"
                        >
                        </v-text-field> -->
                        <v-text-field
                            v-model="finishData.representativeTel"
                            class="text-field pt-0"
                        >
                        </v-text-field>
                        </div>
                        <div>
                            <span class="label-title">メールアドレス</span>
                        <v-text-field
                            v-model="finishData.representativeEmail"
                            class="text-field pt-0"
                            :rules="[$rules.isEmailValid]"
                        >
                        </v-text-field>
                        </div>
                        <div>
                            <span class="label-title">住所</span>
                        <v-text-field
                            v-model="finishData.representativeAddress1"
                            class="text-field pt-0"
                            :rules="[$rules.checkLenghInput(255)]"
                        >
                        </v-text-field>
                        </div>
                    </div>
                </v-col>
                <!-- End Right Input -->
            </v-row>
        </v-form>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions, mapMutations } from 'vuex'
import { checkNumber, isEmailValid, checkKatakana } from '@/utils/validators'
import { BOOKING_TYPES_LIST } from '@/api/graphql/bookingDetail/booking-detail-basic'
import { checkPermissionUserCurrent } from '@/utils/permissions'
import { FACILITY_LIST } from '@/api/graphql/facility-settings'
import gql from 'graphql-tag'
import { isoDate } from '@/utils/dateUtil'

export default {
  name: 'NewBooking',
  data () {
    return {
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      valid: true,
      facility: this.$route.query.facility, // select at no.50, show at left side
      roomTypeName: this.$route.query.roomTypeName, // select at no.50, show at left side
      checkOutDate: moment(this.$route.query.checkInDate).add(parseInt(this.$route.query.night), 'days').format('YYYY-MM-DD'), // select at no.50, show at left side
      finishData: {
        // RequireData
        days: [
          {
            inventoryTypeId: 1
          }
        ],
        bookingTypeId: 11, // hard set
        // left input
        priorityBooking: false,
        checkInDate: this.$route.query.checkInDate, // select at no.50, show at left side, require
        numberOfAdults: 1,
        numberOfChildren: 0,
        numberOfChildrenWithBedShare: 0,
        // right input
        representativeClientId: null,
        representativeSubMemberId: null,
        bookingBarNote: null,
        representativeName: '',
        representativeKana: '',
        representativeTel: '',
        representativeEmail: '',
        representativeAddress1: '',
        roomType: null
      },
      rules: {
        number: [
          v => (v && checkNumber(v)) || !v || this.$t('rules.numberInvalid'),
          v =>
            (v && v <= 2147483647) || !v || this.$t('rules.maximumNumberInt32')
        ],
        numberOfAdults: [
          v => (v && checkNumber(v)) || !v || this.$t('rules.numberInvalid'),
          v =>
            (v && v <= 2147483647) || !v || this.$t('rules.maximumNumberInt32')
        ],
        numberOfChildren: [
          v => (v && checkNumber(v)) || !v || this.$t('rules.numberInvalid'),
          v =>
            (v && v <= 2147483647) || !v || this.$t('rules.maximumNumberInt32')
        ],
        numberOfChildrenWithBedShare: [
          v => (v && checkNumber(v)) || !v || this.$t('rules.numberInvalid'),
          v =>
            (v && v <= 2147483647) || !v || this.$t('rules.maximumNumberInt32')
        ],
        kana: [
          v => (v && v.length <= 255) || !v || this.$t('rules.maximumNCharacter', { value: 255 }),
          (v) => this.checkKatakana(v) || this.$t('rules.isKatakana')
        ],
        tel: [
          v =>
            !v ||
            checkNumber(v) ||
            this.$t('rules.numberInvalid'),
          v => (v && v.length <= 15) || !v || this.$t('rules.maximumNCharacter', { value: 15 })
        ],
        text: [
          v => (v && v.length <= 255) || !v || this.$t('rules.maximumNCharacter', { value: 255 })
        ],
        email: [
          v =>
            !v ||
            isEmailValid(v) ||
            this.$t('rules.emailIsInvalid'),
          v => (v && v.length <= 255) || !v || this.$t('rules.maximumNCharacter', { value: 255 })
        ]
      }
    }
  },

  computed: {
    roomTypeId () {
      return parseInt(this.$route.query.roomTypeId)
    },
    maxOccupancy () {
      return this.roomType?.maxOccupancy ?? 10
    },
    numberOfAdultItems () {
      return [...Array(this.maxOccupancy)].map((_, index) => index + 1) // + 1 because adult=0 is not allowed
    },
    numberOfChildItems () {
      return [...Array(this.maxOccupancy + 1)].map((_, index) => index) // + 1 because adult=0 is not allowed
    },
    numberOfBedshareItems () {
      return this.numberOfChildItems // same as children for now
    }
  },

  mounted () {
    this.getBookingTypeList()
    this.getFacilityList()
  },
  methods: {
    ...mapActions(['createNewBookingNoCustomerID']),
    ...mapMutations(['setAlertSuccess', 'setAlertError', 'setBookingType']),
    checkNumber,
    checkKatakana,
    async getFacilityList () {
      await this.$apollo.query({
        query: gql`${FACILITY_LIST}`
      }).then((data) => {
        const itemFac = data.data.facilityList.find(item => item.id === parseInt(this.$route.query.facilityID))
        if (itemFac) {
          this.facility = itemFac.name
        }
      }).catch((error) => {
        console.error(error)
      })
    },
    async getBookingTypeList () {
      await this.$apollo
        .query({
          query: gql`${BOOKING_TYPES_LIST}`
        })
        .then((data) => {
          const bookingTypesList = data.data.bookingTypesList
          this.setBookingType({ bookingTypesList: bookingTypesList })
        })
        .catch((error) => {
          console.error(error)
        })
    },
    createBookingNoCustomerId () {
      const getDataSelect = JSON.parse(localStorage.getItem('selectedReservationRooms'))
      if (getDataSelect) {
        var result = Object.keys(getDataSelect).sort().map((key) => [key, getDataSelect[key]])
        const days = []
        for (let i = 0; i < result.length; i++) {
          days.push({ inventoryTypeId: result[i][1].inventoryTypeId })
        }
        this.finishData.days = days
      }
      if (this.$refs.form.validate()) {
        const newFinishData = {
          ...this.finishData,
          roomTypeId: this.roomTypeId
        }
        if (newFinishData.representativeEmail === '') {
          newFinishData.representativeEmail = null
        }
        if (newFinishData.numberOfAdults === '') {
          newFinishData.numberOfAdults = null
        } else {
          newFinishData.numberOfAdults = parseInt(newFinishData.numberOfAdults)
        }
        if (newFinishData.numberOfChildren === '') {
          newFinishData.numberOfChildren = null
        } else {
          newFinishData.numberOfChildren = parseInt(newFinishData.numberOfChildren)
        }
        if (newFinishData.numberOfChildrenWithBedShare === '') {
          newFinishData.numberOfChildrenWithBedShare = null
        } else {
          newFinishData.numberOfChildrenWithBedShare = parseInt(newFinishData.numberOfChildrenWithBedShare)
        }
        const finalize = () => this.createNewBookingNoCustomerID(newFinishData)
        if (newFinishData.checkInDate < isoDate(new Date())) {
          // if checkInDate is in the past, show confirm dialog TO2020-937
          this.$confirm({
            message: '過去の日付で予約を作成します。よろしいですか？',
            ok: finalize
          })
        } else {
          finalize()
        }
      } else {
        this.setAlertError(this.$t('messages.newCreationFailed'))
      }
    },

    formatDate (value) {
      var d = moment(value).format('dddd')
      switch (d) {
        case 'Sunday':
          d = '日'
          break
        case 'Monday':
          d = '月'
          break
        case 'Tuesday':
          d = '火'
          break
        case 'Wednesday':
          d = '水'
          break
        case 'Thursday':
          d = '木'
          break
        case 'Friday':
          d = '金'
          break
        case 'Saturday':
          d = '土'
          break
      }
      if (moment(value) > moment(this.toDate)) {
        this.toDate = value
      }
      return moment(value).format('yyyy年M月D日 (' + d + ')')
    }
  },
  apollo: {
    roomType: {
      query: gql`
      query($id: Int!) { roomType(id: $id) { id maxOccupancy }}
      `,
      variables () {
        return { id: this.roomTypeId }
      },
      update: data => data.roomType
    }
  }
}
</script>

<style scoped lang="scss">
.label-title {
    color: #000000;
    font-size: 10px;
    font-weight: 600;
}
.text-field{
 color: #444444;
 font-size: 14px;
 font-weight: 500;
 margin-bottom: 0;
}
.combo-box{
  width: 100px;
  ::v-deep{
    .v-select__slot{
      input {
        color: #444444 !important;
        font-size: 14px !important;
        font-weight: 500 !important;
        margin-bottom: 0 !important;
      }
    }
  }
}
.date-string{
  color: #000000;
  font-size: 10px;
  font-weight: 500;
}
.night-day{
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}
</style>
